// Data entry for site  arrays,   pics locations consts but not really functions

const BlockData = [
        
               {
            id:1,
            headline:"Flight Officer, Viggo - Schiphol, Schiphol" ,
            description:"Mei 2023 - juli 2024",
            extra:["Comminucatie tussen de gate, platform, bagage kelder, piloten/cabincrew, vlucht coordinatie.",
                "Platform team briefen en aansturen.",
                "Waarborgen van veiligheids voorschriften op het platform.",
                "Belading opmaken en doorgeven aan de crew en verwerken in het systeem",
                "Vliegtuigen indocken en jet bridges aansluiten.",
                "Baggage laden/lossen comform weight & balance."
            ],
            skills:["Probleemoplossend", "Stressbestendig", "Flexibel","Leidinggeven","Zelfstandig"]
        },
        {
            id:2,
            link:"",
            headline:" Operator, Jonker & Shut, Barneveld" ,
            description:"Mei 2022 - september 2022",
            extra:["Machines afstellen voor productie",
                "producten in de menger storten..",
                "klein onderhoud en monitoring van de machines."
            ],
            skills:["Probleemoplossend","Zelfstandig","Leidinggeven"]
        },
        {
            id:3,
            link:"",
            headline:"Control room / Proces operator, KONINKLIJKE EUROMA B.V, Zwolle" ,
            description:"November 2019 - Maart 2022",
            extra:[" Proces van de menginstallatie in de gaten houden.",
                "Orders controleren qua grondstoffen en bij afwijkingen de juiste actie ondernemen zodat het proces niet stil komt te liggen.",
                "Het geautomatiseerde hoogbouw magazijn beheren waaronder data verwerking in de verschillende applicaties waar mee we werken en de fysieke problemen als die zich voordoen. (kratjes die per ongeluk van de lift vallen / sensoren die fout staan etc.)",
                "De automatiche IBC movers  monitoren en bij fouten resetten en de route opnieuw inplannen.",
                "Het team aansturen om volgens de Food safety protocollen te werken en een veilige werkplek te garanderen en de quality van de producten hoog te houden."
            ],
            skills:["Probleemoplossend", "Stressbestendig","Nauwkeurigheid","Plannen","Comminucatie"]
        },
        {
            id:4,
            headline:" Heftruckchauffeur, Intertaste, Utrecht" ,
            description:"Juli 2016 - November 2019",
            extra:["Documentatie opstellen en verwerken van de inkomende en uitgaande goederen.",
                "Logistiek administratief werk :  Vrachtwagens bestellen / corrigeren foutieve gegevens in SAP /  bijhouden in en uitgifte.",
                "Vrachtwagens laden/lossen.",
                "Magazijn bevoorraden na binnenkomst + verwerken in SAP.",
                "Pallets halen / brengen stapelaars vullen etc.",
                "Intertaste is overgekocht door Koninklijke Euroma B.V"
            ],
            skills:["Probleemoplossend","Zelfstandig","Flexibel","plannen"]
        }

]





export default BlockData