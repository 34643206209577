import FotoMe from './media/logo.png';
import React from 'react'

function Sidebar (){
        return (
          <>
<aside class="sidebar">
    <div>
      <img src={FotoMe}  alt="Jordi Hendrikse" class="profile-image" />
      <h2>Jordi Hendrikse</h2>
    </div>
    <div class="skillssidebar">
      <span class="skill">plannen</span>
      <span class="skill">stressbestendig</span>
      <span class="skill">zelfstandigheid</span>
      <span class="skill">nauwkeurigheid</span>
      <span class="skill">leidinggeven</span>
      <span class="skill">focus</span>
      <span class="skill">collegialiteit</span>
    </div>
    <div class="social-icons">
      <a href="https://github.com/dantanian" target="_blank" rel="noreferrer"><i class="fab fa-github"></i></a>
      <a href="https://linkedin.com/in/j-hendrikse" target="_blank" rel="noreferrer"><i class="fab fa-linkedin"></i></a>
      <a href="https://fotos.jhendrikse.nl" target="_blank" rel="noreferrer"><i class="fa-solid fa-camera"></i></a>
    </div>
    
    <div class="contact-info">
      <p><i class="fas fa-phone"></i> +31 6 49776813</p>
      <p><i class="fas fa-envelope"></i> <a class='blanklink' href="mailto:Jordi@jhendrikse.nl">Jordi@jhendrikse.nl</a></p>
      <p><i class="fa-solid fa-tag"></i> <a class='blanklink' href='https://mildemuis.nl'> Milde Muis </a></p>
    </div>
  </aside>
</>
)}
export default Sidebar;