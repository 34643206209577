import BlockData from './BlockData'
import React from 'react'

function Blocks (){
        return (
          <>
             {BlockData.map( function(data){
      return (
    <div key={data.id} class="workhistory">
      <h3>{data.headline}</h3>
      <p>{data.description}</p>
      <p>{(data.extra !== "") &&
         <p>{data.extra.map((item) => {
             return  <ul>{item}</ul>
                     
                }) 
            }
         </p>
         }
      </p>
      <div class="skills" >
      {(data.skills !== "") &&
         <p>{data.skills.map((skill) => {
             return   <span class="skill">{skill}</span>
                     
                }) 
            }
         </p>
         }
      </div>
    </div>
    )})}
    </>  
    )
}

export default Blocks