import ProjectData from './ProjectData'
import React from 'react'

function Projects (){
        return (
          <>
             {ProjectData.map( function(data){
      return (
    <div key={data.id} class="project">
      <h3>{data.headline}</h3>
      <p><a style={{textDecoration:'none' , color:'black'}} href={data.link}> {data.description}</a></p>
      <p>{(data.extra !== "") &&
         <p>{data.extra.map((item) => {
             return  <ul>{item}</ul>
                     
                }) 
            }
         </p>
         }
      </p>
      <div class="skills" >
      {(data.skills !== "") &&
         <p>{data.skills.map((skill) => {
             return   <span class="skill">{skill}</span>
                     
                }) 
            }
         </p>
         }
      </div>
    </div>
    )})}
    </>  
    )
}

export default Projects