import './App.css';
import Main from './Components/Main';
import Sidebar from './Components/Sidebar';

//Imports to the main app. no data

function App() {
  return (
      <div className='App'>
              <Sidebar />
              <Main />
      </div>
  );
}
export default App;
