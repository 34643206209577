import Blocks from './js/blocks';
import Projects from './js/Projects';
import React from 'react'

function Main (){
        return (
<>
  <main class="main-content">
    <h1>CV 2024</h1>
    <h2>Job History</h2>
    <Blocks />
    <h2>Projecten / Hobby's</h2>
    <Projects />
    <div class="project">
    <h3>1993 Mazda MX-5</h3>
    <p>  Ik heb Mid 2023 een Mazda MX5 aangeschaft,  en ben momenteel bezig met wat kleine aanpassingen aan de auto zoals. </p>
     <p><i class="fa-solid fa-wrench"></i> Kleur naar Oranje (wrap / spuiten)</p>
     <p><i class="fa-solid fa-wrench"></i> Schroefset (Moet nog)</p>
     <p><i class="fa-solid fa-wrench"></i> Led Lampen(Voor = achter)</p>
     <p><i class="fa-solid fa-wrench"></i> Winkmod (Opnieuw)</p>
 </div>
</main>

</>
)}
export default Main;