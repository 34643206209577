// Data entry for site  arrays,   pics locations consts but not really functions
const ProjectData = [
        
        {
            id:1,
            link:"https://fotos.jhendrikse.nl",
            headline:"Fotografie" ,
            description: "Autos, landschappen, portretten en meer. ", 
            extra:[],
            skills:[]
        },
        {
            id:2,
            link:"",
            headline:"Parachute Springen" ,
            description: "Vanuit meer dan vier kilometer hoogte uit een vliegtuig springen en met hoge snelheid naar de aarde terugstorten om daarna veilig te landen onder een parachute", 
            extra:[],
            skills:[]
        },

];





export default ProjectData